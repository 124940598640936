import { ACTIONS } from '../constants/companies';
import { MODE } from '../constants/mode';

const INITIAL_STATE = {
  companiesList: { companies: [], params: {}, pager: null, error: null, loading: false },
  activeCompany: { company: null, error: null, mode: null },
};

export const companies = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ACTIONS.COMPANY_CREATE_RESET:
    case ACTIONS.COMPANY_UPDATE_RESET:
    case ACTIONS.COMPANY_DELETE_RESET:
    case ACTIONS.COMPANY_FETCH_RESET:
      return {
        ...state,
        activeCompany: {
          company: null,
          loading: false,
          error: null,
          mode: null,
        },
      };

    //================================== COMPANY CREATE START ==================================//
    case ACTIONS.COMPANY_CREATE_START:
      return {
        ...state,
        activeCompany: {
          company: action.payload,
          loading: true,
          error: null,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.COMPANY_CREATE_FAILURE:
      return {
        ...state,
        activeCompany: {
          company: null,
          loading: false,
          error: action.payload,
          mode: MODE.CREATE,
        },
      };
    case ACTIONS.COMPANY_CREATE_SUCCESS:
      return {
        ...state,
        companiesList: {
          ...state.companiesList,
          companies: state.companiesList.companies.concat(action.payload),
          error: null,
          loading: false,
        },
        activeCompany: {
          company: action.payload,
          loading: false,
          error: null,
          mode: MODE.CREATE,
        },
      };
    //==================================  COMPANY CREATE END  ==================================//

    //================================== COMPANY UPDATE START ==================================//
    case ACTIONS.COMPANY_UPDATE_START:
      return {
        ...state,
        activeCompany: {
          company: action.payload,
          loading: true,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.COMPANY_UPDATE_FAILURE:
      return {
        ...state,
        activeCompany: {
          company: null,
          loading: false,
          error: action.payload,
          mode: MODE.UPDATE,
        },
      };
    case ACTIONS.COMPANY_UPDATE_SUCCESS:
      return {
        ...state,
        companiesList: {
          ...state.companiesList,
          companies: state.companiesList.companies.map(comp => {
            if (comp.id === action.payload.id) {
              comp = action.payload;
            }
            return comp;
          }),
          error: null,
          loading: false,
        },
        activeCompany: {
          company: action.payload,
          loading: false,
          error: null,
          mode: MODE.UPDATE,
        },
      };
    //==================================  COMPANY UPDATE END  ==================================//

    //================================== COMPANY DELETE START ==================================//
    case ACTIONS.COMPANY_DELETE_START:
      return {
        ...state,
        activeCompany: {
          company: action.payload,
          loading: true,
          error: null,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.COMPANY_DELETE_FAILURE:
      return {
        ...state,
        activeCompany: {
          company: null,
          loading: false,
          error: action.payload,
          mode: MODE.DELETE,
        },
      };
    case ACTIONS.COMPANY_DELETE_SUCCESS:
      return {
        ...state,
        companiesList: {
          ...state.companiesList,
          companies: state.companiesList.companies.filter(
            company => company.id !== action.payload.id
          ),
          error: null,
          loading: false,
        },
        activeCompany: {
          company: action.payload,
          loading: false,
          error: null,
          mode: MODE.DELETE,
        },
      };
    //==================================  COMPANY DELETE END  ==================================//

    //================================== COMPANY FETCH START ==================================//
    case ACTIONS.COMPANY_FETCH_START:
      return {
        ...state,
        activeCompany: {
          company: action.payload,
          loading: true,
          error: null,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.COMPANY_FETCH_FAILURE:
      return {
        ...state,
        activeCompany: {
          company: null,
          loading: false,
          error: action.payload,
          mode: MODE.VIEW,
        },
      };
    case ACTIONS.COMPANY_FETCH_SUCCESS:
      return {
        ...state,
        activeCompany: {
          company: action.payload,
          loading: false,
          error: null,
          mode: MODE.VIEW,
        },
      };
    //==================================  COMPANY FETCH END  ==================================//

    //================================== COMPANIES FETCH START ==================================//
    case ACTIONS.COMPANIES_FETCH_START:
      return {
        ...state,
        companiesList: {
          companies: [],
          params: action.payload,
          pager: null,
          error: null,
          loading: true,
        },
      };
    case ACTIONS.COMPANIES_FETCH_SUCCESS:
      return {
        ...state,
        companiesList: {
          ...state.companiesList,
          companies: action.payload.results,
          pager: action.payload.paginator,
          error: null,
          loading: false,
        },
      };
    case ACTIONS.COMPANIES_FETCH_FAILURE:
      return {
        ...state,
        companiesList: {
          ...state.companiesList,
          companies: [],
          pager: null,
          error: action.payload,
          loading: false,
        },
      };
    case ACTIONS.COMPANIES_FETCH_RESET:
      return {
        ...state,
        companiesList: {
          companies: [],
          params: null,
          pager: null,
          error: null,
          loading: false,
        },
      };
    //==================================  COMPANIES FETCH END  ==================================//

    default:
      return state;
  }
};
