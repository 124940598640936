// ../constants/companies.js
export const ACTIONS = {
  COMPANY_CREATE_START: 'COMPANY_CREATE_START',
  COMPANY_CREATE_SUCCESS: 'COMPANY_CREATE_SUCCESS',
  COMPANY_CREATE_FAILURE: 'COMPANY_CREATE_FAILURE',
  COMPANY_CREATE_RESET: 'COMPANY_CREATE_RESET',

  COMPANY_UPDATE_START: 'COMPANY_UPDATE_START',
  COMPANY_UPDATE_SUCCESS: 'COMPANY_UPDATE_SUCCESS',
  COMPANY_UPDATE_FAILURE: 'COMPANY_UPDATE_FAILURE',
  COMPANY_UPDATE_RESET: 'COMPANY_UPDATE_RESET',

  COMPANY_DELETE_START: 'COMPANY_DELETE_START',
  COMPANY_DELETE_SUCCESS: 'COMPANY_DELETE_SUCCESS',
  COMPANY_DELETE_FAILURE: 'COMPANY_DELETE_FAILURE',
  COMPANY_DELETE_RESET: 'COMPANY_DELETE_RESET',

  COMPANY_FETCH_START: 'COMPANY_FETCH_START',
  COMPANY_FETCH_SUCCESS: 'COMPANY_FETCH_SUCCESS',
  COMPANY_FETCH_FAILURE: 'COMPANY_FETCH_FAILURE',
  COMPANY_FETCH_RESET: 'COMPANY_FETCH_RESET',

  COMPANIES_FETCH_START: 'COMPANIES_FETCH_START',
  COMPANIES_FETCH_SUCCESS: 'COMPANIES_FETCH_SUCCESS',
  COMPANIES_FETCH_FAILURE: 'COMPANIES_FETCH_FAILURE',
  COMPANIES_FETCH_RESET: 'COMPANIES_FETCH_RESET',
};
